@use "variables" as *;

@mixin shimmer {
    -webkit-animation: placeholderShimmer 2s linear 0s infinite normal forwards;
    animation: placeholderShimmer 2s linear 0s infinite normal forwards;
    background: var(--shimmer-light-color);
    background-image: linear-gradient(to right, var(--shimmer-light-color) 0%, var(--shimmer-dark-color) 20%, var(--shimmer-light-color) 40%, var(--shimmer-light-color) 100%);
    background-repeat: no-repeat;
    background-size: 800px 100%;
}

@mixin truncate-vertical($line, $height) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: $line;
    height: $height;
}

@mixin flex-config($display, $flex-direction:null, $justify-content:null, $align-items:null) {
    @if $display==none {
        display: none;
    }

    @else if $display==flex {
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
    }

    @else if $display==inline-flex {
        display: -moz-inline-box;
        display: -ms-inline-flexbox;
        display: -webkit-inline-flex;
        display: inline-flex;
    }

    @if $flex-direction==$flex-direction {
        -webkit-flex-direction: $flex-direction;
        -moz-flex-direction: $flex-direction;
        -ms-flex-direction: $flex-direction;
        flex-direction: $flex-direction;
    }

    @if $justify-content==$justify-content {
        -webkit-justify-content: $justify-content;
        -moz-justify-content: $justify-content;
        -ms-justify-content: $justify-content;
        justify-content: $justify-content;
        -ms-flex-pack: $justify-content;
    }

    @if $align-items==$align-items {
        -webkit-align-items: $align-items;
        -moz-align-items: $align-items;
        -ms-align-items: $align-items;
        align-items: $align-items;
    }
}

@mixin linear-gradient($gradientValues...) {
    background: -webkit-linear-gradient($gradientValues);
    background: -moz-linear-gradient($gradientValues);
    background: -ms-linear-gradient($gradientValues);
    background: -o-linear-gradient($gradientValues);
    background: linear-gradient($gradientValues);
}

@mixin transform($value) {
    -webkit-transform: $value;
    -moz-transform: $value;
    -ms-transform: $value;
    transform: $value;
}

@mixin translate($x, $y) {
    -webkit-transform: translate($x, $y);
    -moz-transform: translate($x, $y);
    -ms-transform: translate($x, $y);
    -o-transform: translate($x, $y);
    transform: translate($x, $y);
}

@mixin border-radius($value, $overflow: hidden) {
    -webkit-border-radius: $value;
    -moz-border-radius: $value;
    -ms-border-radius: $value;
    border-radius: $value;
    overflow: $overflow;
}

@mixin rotate($deg) {
    @include transform(rotate(#{$deg}deg));
}

@mixin transition($transition...) {
    -moz-transition: $transition;
    -o-transition: $transition;
    -webkit-transition: $transition;
    transition: $transition;
}

@mixin center($position) {
    position: absolute;

    @if $position=="vertical" {
        top: 50%;
        @include transform(translateY(-50%));
    }

    @else if $position=="horizontal" {
        left: 50%;
        @include transform(translateX(-50%));
    }

    @else if $position=="both" {
        top: 50%;
        left: 50%;
        @include transform(translate(-50%, -50%));
    }
}

@mixin position($top, $right, $bottom, $left) {
    position: absolute;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}

@mixin position-style($combo,
  $top: null,
  $right: null,
  $bottom: null,
  $left: null,
  $position: absolute) {
  position: $position;

  @if $combo ==null {
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
  }

  @else {
    @if $combo =="top-left" {
      top: 0;
      left: 0;
    }

    @else if $combo =="top-right" {
      top: 0;
      right: 0;
    }

    @else if $combo =="bottom-left" {
      bottom: 0;
      left: 0;
    }

    @else if $combo =="bottom-right" {
      bottom: 0;
      right: 0;
    }

    @else if $combo=="center" {
      top: 50%;
      left: 50%;
      @include transform(translate(-50%, -50%));
    }

    @else if $combo=="y-center" {
      top: 50%;
      right: $right;
      left: $left;
      @include transform(translateY(-50%));
    }

    @else if $combo=="x-center" {
      left: 50%;
      top: $top;
      bottom: $bottom;
      @include transform(translateX(-50%));
    }
  }
}

// how to use
// @include position(null,1rem,1rem);
// @include position(top-left);
// @include position(x-center,0);
// @include position(y-center,null,null,null,0);
// @include position(top-left,null,null,null,null,fixed);

@mixin box-shadow($shadow...) {
    -webkit-box-shadow: $shadow;
    -moz-box-shadow: $shadow;
    box-shadow: $shadow;
}

@mixin card-count($count, $gap: 0rem) {
    flex-basis: calc((100% / $count) - $gap);
    width: calc((100% / $count) - $gap);
    margin-left: calc($gap / 2);
    margin-right: calc($gap / 2);
}

@mixin grid-style {
    &-item {
        flex-basis: 100%;
        width: 100%;
        padding-bottom: 0%;

        &::after {
            display: none;
        }

    }

    &-title {
        margin: 0;
    }

    &-wrap {
        @include flex-config(flex, null, null, null);
    }

    &-thumbnail {
        flex-basis: 30%;
        width: 30%;
    }

    &-content {
        flex-basis: 70%;
        width: 70%;
        padding-left: var(--half-space);
        @include flex-config(flex, column, space-between, null);
    }
}

@mixin absolute-video-content ($color) {
    .item-type-video {
        &.article-item {
            .item-type-icon {
                bottom: calc(var(--listing-article-title-height) * 2);
            }
        }

        .article {
            &-content {
                width: calc(100% - calc(var(--full-space) * 2));
                margin: 0 auto;
                padding: var(--full-space) 0;
                @include position(null, 0, 0, 0);
            }

            &-title {
                color: var($color);
            }

            &-meta {
                .meta {
                    color: var($color);
                }
            }



            &-thumbnail {
                clip-path: polygon(0% 0%, 100% 0, 100% 96%, 94% 100%, 0% 100%);
            }


        }
    }
}

@mixin thumb-clip ($top, $bottom) {
    clip-path: polygon(0% 0%, 100% 0, 100% $top, $bottom 100%, 0% 100%);
}

@mixin icon-clip {
    clip-path: polygon(0% 0%, 78% 0, 100% 21%, 100% 96%, 0% 100%);
}


//
@mixin bottomShape($one:0,$right:1rem) {
	clip-path: polygon(100% 0, 100% calc(100% - $right), calc(100% - $right) 100%, $right 100%, 0% 100%, 0 0);
	-webkit-clip-path:polygon(100% 0, 100% calc(100% - $right), calc(100% - $right) 100%, $right 100%, 0% 100%, 0 0);
}
@mixin grid-list {
    .article {
        &-description {
            margin-bottom: var(--full-space);

            p {
                font-size: var(--featured-article-desc-size);
                @include truncate-vertical(2, var(--featured-article-desc-height));
            }
        }

        &-item {
            flex-basis: 100%;
            width: 100%;

            &::after {
                height: .1rem;
            }
        }
    }

    .second-list {
        .article {
            @include grid-style();
        }
    }

    .third-list {
        .article {
            &-item {
                margin-bottom: var(--half-space);
                padding-bottom: var(--half-space);
            }

            &-title {
                margin: 0;
                @include truncate-vertical(1, 1.9rem);
            }
        }
    }

    .first-list {
        .article-title {
            margin: var(--half-space) 0;
            font-size: var(--featured-article-title-size);
            @include truncate-vertical(3, var(--featured-article-title-height));
        }
    }
}

@mixin standing-page-theme($sport-name) {
    .standing-page {
        .table {
            &-data {
                .text {
                    color: var(--#{$sport-name}-secondary-color);
                }

            }

            &-head {
                .table-data {
                    background-color: var(--#{$sport-name}-secondary-color);
                }

            }

            &-body {
                .highlight .row-head .table-data {
                    background-color: rgba(var(--#{$sport-name}-primary-color-rgb), 0.5);

                }

                .table-data {
                    &.points .text {
                        color: var(--#{$sport-name}-accent-color);
                    }
                }
            }
        }
    }
}

@mixin font($font-size, $line-height: null, $font-family: null) {
	@if $font-family !=null and $line-height !=null and $line-height !=inherit {
		font: calc($font-size * 0.1rem) / calc($line-height * 0.1rem) $font-family;
	}

	@else if $font-family !=null and $line-height ==null and $line-height !=inherit {
		font: calc($font-size * 0.1rem) / calc(($font-size * 0.1rem) + 0.4rem) $font-family;
	}

	@else if $font-family ==null and $line-height ==null {
		font-size: calc($font-size * 0.1rem);
		line-height: calc(($font-size * 0.1rem) + 0.4rem);
	}

	@else if $font-family !=null and $line-height ==inherit {
		font: calc($font-size * 0.1rem) $font-family;
	}

	@else if $font-family ==null and $line-height !=null and $line-height !=inherit {
		font-size: calc($font-size * 0.1rem);
		line-height: calc($line-height * 0.1rem);
	}
}

@mixin truncate-vertical-line($font-size, $line-number, $line-height: null) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: $line-number;
    font-size: calc($font-size * 0.1rem);

    @if $line-height ==null {
        line-height: calc(($font-size * 0.1rem) + 0.2rem);
        height: calc($line-number * (($font-size * 0.1rem) + 0.2rem));
    }

    @else {
        line-height: $line-height;
        height: calc($line-number * $line-height);
    }
}