@use "./config" as *;
@use "./main-scss/tables";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: var(--base-font-size);
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: $font-regular, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
    "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  background-color: var(--page-background-color);
  color: var(--black-color);
  font-size: 1.4rem;
  line-height: 1.4;
}

.amp-page-header {
  padding: 0 var(--half-space);
  height: var(--bottom-header-height);
  background-color: var(--kabaddi-secondary-color);
  border-bottom: 0.2rem solid var(--kabaddi-accent-color);
  @include flex-config(flex, null, space-between, center);
  @include box-shadow(0 0.3rem 0.6rem rgba(var(--black-color-rgb), 10%));
}
.site-logo {
  padding: var(--half-space) 0;
  .logo {
    position: relative;
    width: var(--logo-width);
    height: var(--logo-height);
    @include flex-config(flex, null, null, null);
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: url(/static-assets/images/cssimages/svg/site-logo.svg?v=1.10) center center no-repeat;
      background-size: contain;
    }
    &-text {
      font-size: 0;
    }
  }
}
.nav-navbar {
  padding: 0;
  margin: 0;
  @include flex-config(flex, null, null, center);

  li {
    margin: 0 calc(3 * var(--half-space));
    list-style: none;
    &.active {
      a {
        color: var(--kabaddi-primary-color);
      }
    }
    a {
      text-decoration: none;
      color: var(--white-color);
      font-family: $font-medium;
    }
  }
}
.site-content {
  padding: var(--full-space);
}
.title-wrap {
  .title {
    font-size: 1.8rem;
    line-height: 1.4;
    font-family: $font-bold;
    color: var(--kabaddi-secondary-color);
  }
  .article {
    &-meta {
      .meta {
        font-size: 1.2rem;
      }
    }

    &-description {
      p {
        font-size: 1.4rem;
        line-height: 2.6rem;
        font-family: $font-medium;
        color: var(--kabaddi-secondary-color);
      }
    }
  }
}

.article-featured-media {
  margin: var(--full-space) 0;

  amp-img,
  amp-iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  + .content-wrap {
    [data-component="SI-image"] {
      &:first-child {
        display: none;
      }
    }
  }
}
.img-16by9 {
  .img-box {
    padding-bottom: 56.25%;
    position: relative;
    overflow: hidden;
    @include border-radius(0.5rem);
  }
}
.head-wrap {
  margin: var(--full-space) 0;
  @include flex-config(flex, null, space-between, center);
}
amp-social-share {
  background-size: 70%;
  @include border-radius(100%);
  &[type="copylink"] {
    background-color: var(--facebook-color);
    &::before {
      content: "\e810";
      font-family: $font-icon;
      color: var(--white-color);
      @include center(both);
    }
  }
}
.social-wrap {
  position: relative;
  &.copy {
    &:after {
      content: "Copied";
      position: absolute;
      bottom: var(--full-space-negative);
      left: 0;
      right: 0;
      margin: 0 auto;
      text-align: right;
      color: var(--kabaddi-secondary-color);
      font-family: $font-medium;
    }
  }
}
p,
li {
  margin-bottom: var(--full-space);
}
.site-footer {
  height: 9rem;
  background: var(--kabaddi-primary-color);
  .content-box {
    height: 9rem;
    background: var(--kabaddi-primary-color);
    text-align: center;
    @include flex-config(flex, column, center, center);
  }
  .copyright,
  .site-address a {
    font-size: 1.2rem;
    color: var(--kabaddi-secondary-color);
    font-family: $font-medium;
  }
  .site-address {
    margin: 0;
  }
}
a {
  color: var(--black-color);
  text-decoration: underline;
}
.title,
.sub-title {
  font-size: 1.8rem;
  font-family: $font-bold;
  margin-bottom: var(--half-space);
}
.sub-title {
  font-size: 1.4rem;
}
strong {
  font-family: $font-bold;
}
ul,
ol {
  padding-left: var(--full-space);
  margin-bottom: var(--full-space);

  li {
    position: relative;

    ::marker {
      font-family: $font-regular;
    }

    ul,
    ol {
      margin-bottom: 0;
    }
  }
}

ol {
  list-style-type: decimal;

  ::marker {
    font: 1.2rem/1.5 $font-bold;
    color: var(--kabaddi-secondary-color);
  }
}

.lower {
  &-roman {
    list-style-type: lower-roman;
  }

  &-alpha {
    list-style-type: lower-alpha;
  }
}

.upper {
  &-roman {
    list-style-type: upper-roman;
  }

  &-alpha {
    list-style-type: upper-alpha;
  }
}

.disc-list {
  list-style-type: disc;

  li {
    position: relative;

    &::marker {
      font: 2rem/1.3 $font-bold;
    }
  }
}
