@use "../config" as *;
.tnc-table{
  width: 100%;
  overflow-x: scroll;
  border-collapse: collapse;
  margin-bottom: var(--full-space);
  thead{
    background: var(--kabaddi-primary-color);
    @include border-radius(var(--full-radius) var(--full-radius) 0 0);
  }
      th{
        border-right: 0.1rem solid rgba(var(--black-color-rgb),0.3);
        &:first-child{
          @include border-radius(var(--full-radius) 0 0 0);
        }
        &:last-child{
          border-right: 0;
          @include border-radius(0 var(--full-radius) 0 0);
        }
      }
  th{
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-family:  $font-bold;
    height: 4.7rem;
    color: var(--kabaddi-secondary-color);
    padding-left: var(--full-space);
    text-align: left;
    border: 0.1rem solid rgba(var(--black-color-rgb), 0.1);
  }
  tbody{
    border: 0.1rem solid rgba(var(--black-color-rgb),0.5);
    tr{
      &:first-child{
        flex-basis: 40%;
      }
    }
  }
  td{
    width: 33.33%;
    padding: var(--full-space);
    border: 0.1rem solid rgba(var(--black-color-rgb),0.1);
    vertical-align: top;
    font-size: 1.5rem;
    font-family:  $font-regular;
    color: var(--kabaddi-secondary-color);
    &:first-child{
      font:1.5rem/1 $font-bold;
      color: var(--kabaddi-secondary-color);
    }
  }
}
