@use "variables" as *;

@font-face {
    font-family: 'Sarabun-Regular';
    src: url('/static-assets/build/fonts/Sarabun-Regular.eot');
    src: url('/static-assets/build/fonts/Sarabun-Regular.eot?#iefix') format('embedded-opentype'),
        url('/static-assets/build/fonts/Sarabun-Regular.woff2') format('woff2'),
        url('/static-assets/build/fonts/Sarabun-Regular.woff') format('woff'),
        url('/static-assets/build/fonts/Sarabun-Regular.ttf') format('truetype'),
        url('/static-assets/build/fonts/Sarabun-Regular.svg#Sarabun-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sarabun-Medium';
    src: url('/static-assets/build/fonts/Sarabun-Medium.eot');
    src: url('/static-assets/build/fonts/Sarabun-Medium.eot?#iefix') format('embedded-opentype'),
        url('/static-assets/build/fonts/Sarabun-Medium.woff2') format('woff2'),
        url('/static-assets/build/fonts/Sarabun-Medium.woff') format('woff'),
        url('/static-assets/build/fonts/Sarabun-Medium.ttf') format('truetype'),
        url('/static-assets/build/fonts/Sarabun-Medium.svg#Sarabun-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Sarabun-Bold';
    src: url('/static-assets/build/fonts/Sarabun-Bold.eot');
    src: url('/static-assets/build/fonts/Sarabun-Bold.eot?#iefix') format('embedded-opentype'),
        url('/static-assets/build/fonts/Sarabun-Bold.woff2') format('woff2'),
        url('/static-assets/build/fonts/Sarabun-Bold.woff') format('woff'),
        url('/static-assets/build/fonts/Sarabun-Bold.ttf') format('truetype'),
        url('/static-assets/build/fonts/Sarabun-Bold.svg#Sarabun-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
  font-family: 'waf-font-icon';
  src: url('/static-assets/build/fonts/waf-font-icon.eot?v=#{$font-version}');
  src: url('/static-assets/build/fonts/waf-font-icon.eot?v=#{$font-version}#iefix') format('embedded-opentype'),
       url('/static-assets/build/fonts/waf-font-icon.woff2?v=#{$font-version}') format('woff2'),
       url('/static-assets/build/fonts/waf-font-icon.woff?v=#{$font-version}') format('woff'),
       url('/static-assets/build/fonts/waf-font-icon.ttf?v=#{$font-version}') format('truetype'),
       url('/static-assets/build/fonts/waf-font-icon.svg?v=#{$font-version}#waf-font-icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}