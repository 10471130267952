:root {
	/* Gujarat Gaints color variables*/
	--primary-color: #DA3832;
	--primary-color-rgb: 218, 56, 50;
	--secondary-color: #3E200E;
	--secondary-color-rgb: 62, 32, 14;
	--tertiary-color: #6b1f07;
	--tertiary-color-rgb: 218, 56, 50;
	--fourth-color: #2e1608;
	--fourth-color-rgb: 46, 22, 8;
	--accent-color: #F0B042;
	--accent-color-rgb: 240, 176, 66;
	--text-color: #0E0E0E;
	--text-color-rgb: 14, 14, 14;
	--pro-kabaddi-gradient: #ED1C24;
	--pro-kabaddi-gradient-rgb: 237, 28, 36;
	--legend-cricket-dark-gradient: #FF161F;
	--legend-cricket-dark-gradient-rgb: 255, 22, 31;
	--legend-cricket-light-gradient: #FFAE00;
	--legend-cricket-light-gradient-rgb: 255, 174, 0;
	--ukk-dark-gradient: #E8AC30;
	--ukk-dark-gradient-rgb: 232, 172, 48;
	--ukk-light-gradient: #3D220F;
	--ukk-light-gradient-rgb: 61, 34, 15;
	--polo-light-gradient: #D6BB7F;
	--polo-light-gradient-rgb: 214, 187, 127;
	--polo-dark-gradient: #907029;
	--polo-dark-gradient-rgb: 114, 112, 41;
	/* kabaddi colors variables*/
	--kabaddi-primary-color: #f3bc44;
	--kabaddi-primary-color-rgb: 243, 188, 68;
	--kabaddi-secondary-color: #3e2110;
	--kabaddi-secondary-color-rgb: 62, 33, 16;
	--kabaddi-accent-color: #da3b34;
	--kabaddi-accent-color-rgb: 218, 59, 52;
	--kabaddi-header-bg: #6b200e;
	--kabaddi-header-bg-rgb: rgb(107, 32, 14);
	--kabaddi-light-gradient: #f7c548;
	--kabaddi-light-gradient-rgb: rgb(247, 197, 72);
	--kabaddi-dark-gradient: #c28500;
	--kabaddi-dark-gradient-rgb: rgb(194, 133, 0);
	--match-center-team: #3E2112;
	--match-center-team-rgb: 62, 33, 18;
	--match-center-runs: #892C16;
	--match-center-runs-rgb: 137, 44, 22;
	--match-center-label: #8A4826;
	--match-center-label-rgb: 138, 72, 38;
	--match-center-balls: #E30613;
	--match-center-balls-rgb: 227, 6, 19;
	--match-center-extra-run: #f3bc44;
	--match-center-extra-run-rgb: 243, 188, 68;
	--match-center-scorecard-img: #EEEEEE;
	--match-center-scorecard-img-rgb: 238, 238, 238;
	--color-link: #0053b3;
	--color-link-rgb: 0, 83, 179;
	/* status color variables*/
	--live-color: #0ac707;
	--recent-color: #da3b34;
	--upcoming-color: #f6f3f3;
	--win-color: #238a46;
	--lost-color: #ea0a2a;
	--draw-color: #ffffff;
	/* common color variables*/
	--black-color: #0e0e0e;
	--black-color-rgb: 0, 2, 13;
	--white-color: #ffffff;
	--white-color-rgb: 255, 255, 255;
	--grey-color: #e0e0e0;
	--grey-color-rgb: 224, 224, 224;
	--light-grey-color: #f2f2f2;
	--light-grey-color-rgb: 242, 242, 242;
	--dark-grey-color: #d3d3d3;
	--dark-grey-color-rgb: 211, 211, 211;
	/* shimmer color variables*/
	--shimmer-light-color: #dedede;
	--shimmer-light-color-rgb: 222, 222, 222;
	--shimmer-dark-color: #aaaaaa;
	--shimmer-dark-color-rgb: 170, 170, 170;
	--body-text-color: #3e2110;
	--body-text-color-rgb: 0, 50, 122;
	--input-bg-color: #12264f0d;
	--input-bg-color-rgb: 18, 38, 79;
	/* social color variables*/
	--facebook-color: #3a589b;
	--twitter-color: #55acee;
	--whatsapp-color: #25d366;
	--instagram-color: #e1306c;
	--youtube-color: #ff0000;
	--copylink-color: #034ea2;
	--gmail-color: #d2091b;
	--copylink-color: #26324d;
	/* height variables*/
	--primary-header-height: 5rem;
	--microsite-primary-header-height: 5rem;
	--secondary-header-height: 5rem;
	--social-link-height: 4rem;
	--footer-bottom-height: 8.5rem;
	--footer-middle-height: 8.5rem;
	--main-footer-height: 17rem;
	--scorestrip-height: 9rem;
	--breadcrumb-height: 4rem;
	--waf-head-height: 6rem;
	--filter-height: 3.5rem;
	--main-header-height: calc(var(--primary-header-height) + var(--secondary-header-height));
	--featured-article-title-height: 6.2rem;
	--featured-article-title-size: 1.6rem;
	--featured-article-desc-height: 3.2rem;
	--featured-article-desc-size: 1.2rem;
	--listing-article-title-height: 4.4rem;
	--listing-article-title-size: 1.6rem;
	/* width variables*/
	--logo-width: 4rem;
	--logo-height: 5rem;
	--standing-width: 40.5rem;
	/* Space variables*/
	--full-space: 1.5rem;
	--full-space-negative: calc(var(--full-space) * -1);
	--half-space: 0.75rem;
	--half-space-negative: calc(var(--half-space) * -1);
	--quarter-space: calc(var(--full-space) / 4); //0.4
	--quarter-space-negative: calc(var(var(--quarter-space)) * -1);
	--three-fourth-space: calc(var(--half-space) + var(--quarter-space)); //1.1
	--three-fourth-space-negative: calc((var(--half-space) + var(--quarter-space)) * -1);
	--one-and-quarter-space: calc(var(--full-space) + var(--quarter-space)); //1.9
	--full-radius: 0.8rem;
	--half-radius: 0.4rem;
	/* Z-index variables*/
	--logo-z: 10;
	--header-z: 10;
	--site-nav-z: 10;
	--scroll-top-z: 8;
	--secondary-menu: 9;
	--content-z: 2;
	--pattern-bg-z: -2;
	--pattern-z: -1;
	--match-btn: 1;
	--accordion-z: 9;
	--select-box-z: 8;
	--standing-head-z: 10;
	--head-bg-z: -2;
	--head-pattern-z: -1;
	--dropdown-z: 1;
	--gradient-z: 1;
	--standing-z: 1;
	--item-type-z: 1;
	--zindex-modal: 9;
	--z-index1: 1;
	--z-index9: 9;
	--z-index10: 10;
	/* ratio variables*/
	--ratio-1by1: 1;
	--ratio-4by3: 0.75;
	--ratio-16by9: 0.5625;
	--ratio-2by3: 1.5;
	--ratio-9by16: 1.77778;
	/*non color variables*/
	--base-font-size: 62.5%;
	--container-max-width: 100%;
	--content-width: var(--window-inner-width);
	--vh: 1vh;
	--vw: 1vw;
	--window-inner-height: calc(var(--vh, 1vh) * 100);
	--window-inner-width: calc(var(--vw, 1vw) * 100);
}
$medium-mobile-min-width: 576px;
$tablet-min-width: 768px;
$tablet-max-width: 992px;
$desktop-min-width: 992px;
$large-desktop-min-width: 1200px;
$xl-desktop-min-width: 1600px;
$font-regular: "Sarabun-Regular", sans-serif;
$font-medium: "Sarabun-Medium", sans-serif;
$font-bold: "Sarabun-Bold", sans-serif;
$font-icon: "waf-font-icon";
$image-version: "2.8";
$font-version: "81061505";
@media (min-width: $tablet-min-width) {
	:root {
		--container-max-width: 72rem;
		--primary-header-height: 7.5rem;
		--logo-width: 8.3rem;
		--logo-height: 8.9rem;
		--footer-bottom-height: 7rem;
		--footer-middle-height: 4rem;
		--main-footer-height: 9rem;
		--secondary-header-height: 7rem;
		--waf-head-height: 8rem;
	}
}
@media (min-width: $desktop-min-width) {
	:root {
		--container-max-width: 96rem;
	}
}
@media (min-width: $large-desktop-min-width) {
	:root {
		--container-max-width: 114rem;
	}
}
@media (min-width: $xl-desktop-min-width) {
	:root {
		--container-max-width: 140rem;
	}
}